<h2 class="dialog-title" mat-dialog-title>{{ data.title }}</h2>
<div class="dialog-content" mat-dialog-content>
  {{ data.message }}
</div>
<div class="dialog-actions" mat-dialog-actions [align]="'center'">
  <button mat-raised-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
    <mat-icon>check</mat-icon>
    {{ data.trueButtonText || 'Ja' }}
  </button>
  <button mat-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
    {{ data.falseButtonText || 'Nein' }}
  </button>
</div>
