import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLinkActive, RouterLinkWithHref } from '@angular/router';

export interface TabLink {
  link: string;
  label: string;
}

@Component({
  selector: 'mp-navigation-tabs-menu',
  standalone: true,
  templateUrl: './navigation-tabs-menu.component.html',
  styleUrl: './navigation-tabs-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLinkWithHref, RouterLinkActive, MatTabsModule],
})
export class NavigationTabsMenuComponent {
  readonly tabLinks: InputSignal<TabLink[]> = input<TabLink[]>([]);
}
