import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OutputEmitterRef,
  WritableSignal,
  output,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatMenuModule } from '@angular/material/menu';

import { InlineEditComponent } from '../../../inline-edit/inline-edit.component';

@Component({
  selector: 'mp-search-view-action-buttons',
  standalone: true,
  templateUrl: './search-view-action-buttons.component.html',
  styleUrl: './search-view-action-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatLegacyButtonModule, MatMenuModule, InlineEditComponent],
})
export class SearchViewActionButtonsComponent {
  @HostBinding() readonly class = 'mp-search-view-action-buttons';

  readonly searchViewCreate: OutputEmitterRef<string> = output<string>();

  readonly searchViewUpdate: OutputEmitterRef<void> = output<void>();

  readonly searchViewRevert: OutputEmitterRef<void> = output<void>();

  protected readonly createSearchViewInputVisible: WritableSignal<boolean> = signal<boolean>(false);

  createSearchView(searchViewName: string): void {
    this.searchViewCreate.emit(searchViewName);
  }

  updateSearchView(): void {
    this.searchViewUpdate.emit();
  }

  revertToSearchView(): void {
    this.searchViewRevert.emit();
  }
}
