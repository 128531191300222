<nav class="context-tabs-bar no-divider" mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
  @for (contextTab of contextTabs(); track $index) {
    <a
      class="context-tabs-bar__tab"
      mat-tab-link
      [active]="selectedTab() && contextTab.value === selectedTab()"
      (click)="selectedTab.set(contextTab.value)"
    >
      <div class="tab-label">
        <span>{{ contextTab.label }}</span>

        @if (contextTab.count; as count) {
          <mp-filter-badge [value]="count" />
        }
      </div>
    </a>
  }
</nav>
<mat-tab-nav-panel #tabPanel>
  <ng-content />
</mat-tab-nav-panel>
