import { ChangeDetectionStrategy, Component, InputSignal, ModelSignal, input, model } from '@angular/core';

import { type ContextTab, ContextTabsComponent } from '../context-tabs';

@Component({
  selector: 'mp-filter-views',
  standalone: true,
  templateUrl: './filter-views.component.html',
  styleUrl: './filter-views.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContextTabsComponent],
})
export class FilterViewsComponent<StaticViewType = unknown> {
  readonly staticViews: InputSignal<ContextTab<StaticViewType>[]> = input<ContextTab<StaticViewType>[]>([]);

  readonly selectedStaticView: ModelSignal<StaticViewType | undefined> = model<StaticViewType | undefined>();
}
