import { Chart, Plugin } from 'chart.js';

import { ChartArea } from '../models';

const DOUGHNUT_INNER_WIDTH_RATIO = 0.75;
const DOUGHNUT_INNER_HEIGHT_RATIO = 0.65;

export function getDoughnutInnerAreaPlugin(
  chartAreaChangeCallback: (chartArea: ChartArea) => void,
): Plugin<'doughnut'> {
  return {
    id: 'chartAreaInfo',
    afterDraw: (chart: Chart<'doughnut'>) => {
      const { chartArea } = chart;
      if (!chartArea) {
        // This case happens on initial chart load
        return;
      }
      const { offsetLeft, offsetTop } = chart.canvas;
      const left: string = offsetLeft + chartArea.width / 2 + 'px';
      const top: string = offsetTop + chartArea.height / 2 + 'px';

      const cutout: string | number = chart.options.cutout as string | number;

      const chartInnerDiameter: number = calculateChartInnerDiameter(cutout, chartArea.width);

      const width: string = DOUGHNUT_INNER_WIDTH_RATIO * chartInnerDiameter + 'px';
      const height: string = DOUGHNUT_INNER_HEIGHT_RATIO * chartInnerDiameter + 'px';

      chartAreaChangeCallback({ width, height, left, top });
    },
  };
}

function calculateChartInnerDiameter(cutout: string | number, chartWidth: number): number {
  if (typeof cutout === 'string') {
    const cutoutValue: number = parseInt(cutout);
    return (chartWidth * cutoutValue) / 100;
  }

  return chartWidth - 2 * cutout;
}
