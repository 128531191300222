import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  InputSignal,
  Signal,
  computed,
  inject,
  input,
} from '@angular/core';
import {
  CategoryScale,
  Chart,
  ChartOptions,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js';

import { ScaleLabelConfig } from '../../models';
import { getLineChartScaleLabelConfig, getScaleDefaultConfig } from '../../utils';
import { AbstractChart } from '../abstract-chart.directive';
import { ChartBaseComponent } from '../chart-base';

Chart.register(LineController, LineElement, PointElement, CategoryScale, LinearScale, Filler);

@Component({
  selector: 'mp-line-chart',
  standalone: true,
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChartBaseComponent],
})
export class LineChartComponent extends AbstractChart<'line'> {
  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  readonly xAxisLabel: InputSignal<string> = input<string>('');

  readonly yAxisLabel: InputSignal<string> = input<string>('');

  protected readonly options: Signal<ChartOptions<'line'>> = computed(() => this.getLineChartDefaultOptions());

  private getLineChartDefaultOptions(): ChartOptions<'line'> {
    const scaleLabelConfig: ScaleLabelConfig = getLineChartScaleLabelConfig(this.elementRef.nativeElement);

    return {
      scales: {
        y: getScaleDefaultConfig(this.yAxisLabel(), scaleLabelConfig),
        x: {
          ...getScaleDefaultConfig(this.xAxisLabel(), scaleLabelConfig),
          grid: {
            lineWidth: 0,
          },
        },
      },
    };
  }
}
