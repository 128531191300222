<mp-chart-base
  type="doughnut"
  [data]="data()"
  [options]="options()"
  [plugins]="doughnutChartPlugins"
  [legendValues]="legendValues()"
  [dataDisplayFn]="dataDisplayFn()"
>
  @if (chartArea(); as chartArea) {
    <!-- TODO - extract to separate component when it is known how to handle this -->
    <div
      class="chart-content"
      [style]="{
        width: chartArea.width,
        height: chartArea.height,
        left: chartArea.left,
        top: chartArea.top
      }"
    >
      <!--
        TODO Handle content when it is decided what exactly we want to keep there.
        If it will be just custom content then it could be handled by <ng-content />.
        Otherwise, if we want to display the most represented data in the dataset
        then it should be handled by some plugin.
      -->
      <ng-content />
    </div>
  }
</mp-chart-base>
