import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { FilterBadgeComponent } from '../filter-badge';

@Component({
  selector: 'mp-filter-details-button',
  standalone: true,
  templateUrl: './filter-details-button.component.html',
  styleUrl: './filter-details-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule, FilterBadgeComponent],
})
export class FilterDetailsButtonComponent {
  readonly activeFiltersCount: InputSignal<number> = input<number>(0);

  readonly label: InputSignal<string> = input<string>('Filter');

  readonly click: OutputEmitterRef<void> = output<void>();
}
