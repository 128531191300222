<div class="dropzone-label">
  <mat-icon class="dropzone-label__icon material-icons-outlined" data-test="file-upload-label__dropzone-label-icon">
    @switch (status()) {
      @case (UploadFormStatus.PreUpload) {
        backup
      }
      @case (UploadFormStatus.Success) {
        download_done
      }
      @case (UploadFormStatus.Error) {
        warning
      }
    }
  </mat-icon>
  <div class="dropzone-label__text">
    <span class="dropzone-label__text--bold" data-test="file-upload-label__dropzone-label-header">
      @switch (status()) {
        @case (UploadFormStatus.PreUpload) {
          {{ acceptedFileTypes() | uppercase }}
        }
        @case (UploadFormStatus.Success) {
          Erfolgreich hochgeladen!
        }
        @case (UploadFormStatus.Error) {
          Fehlgeschlagen!
        }
      }
    </span>
    <span data-test="file-upload-label__dropzone-label-subheader">
      @switch (status()) {
        @case (UploadFormStatus.PreUpload) {
          max. 500MB pro Datei
        }
        @case (UploadFormStatus.Success) {
          Du erhältst in Kürze eine Email.
        }
        @case (UploadFormStatus.Error) {
          {{ errorMessage() }}
        }
      }
    </span>
  </div>
</div>

<button class="upload-button" mat-stroked-button>
  <mat-icon class="upload-button__icon material-icons-outlined">{{ iconName() }}</mat-icon>
  <span data-test="file-upload-label__upload-button-text">
    @switch (status()) {
      @case (UploadFormStatus.PreUpload) {
        {{ buttonLabel() }}
      }
      @case (UploadFormStatus.Success) {
        Weitere {{ buttonLabel() }}
      }
      @case (UploadFormStatus.Error) {
        Erneut {{ buttonLabel() }}
      }
    }
  </span>
</button>
