<!-- NOTE: We have to use defer to make sure that component's styles are loaded
  due to changing the scales labels based on css variables
-->
@defer (on viewport) {
  <mp-chart-base
    type="line"
    [data]="data()"
    [options]="options()"
    [dataDisplayFn]="dataDisplayFn()"
    legendPosition="top"
    legendItemsDirection="horizontal"
  />
} @placeholder {
  <span></span>
}
