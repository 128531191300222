import { formatNumber } from '@angular/common';
import { Directive, InputSignal, LOCALE_ID, ViewChild, inject, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChartData } from 'chart.js';
import { Subject } from 'rxjs';

import { DataDisplayFn } from '../models';

import { ChartAvailableType, ChartBaseComponent } from './chart-base';

@Directive()
export class AbstractChart<T extends ChartAvailableType> {
  private static redrawChartSubject$: Subject<void> = new Subject<void>();

  @ViewChild(ChartBaseComponent) readonly chartBaseComponent!: ChartBaseComponent<T>;

  readonly data: InputSignal<ChartData<T>> = input.required<ChartData<T>>();

  readonly dataDisplayFn: InputSignal<DataDisplayFn> = input<DataDisplayFn>((value) =>
    formatNumber(value, this.locale),
  );

  readonly locale: string = inject(LOCALE_ID);

  constructor() {
    AbstractChart.redrawChartSubject$
      .pipe(takeUntilDestroyed())
      .subscribe(() => requestAnimationFrame(() => this.chartBaseComponent.refreshChart()));
  }

  static redrawChart(): void {
    AbstractChart.redrawChartSubject$.next();
  }
}
