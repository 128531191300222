import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';

import { InfoDialogData } from './info-dialog-data';

@Component({
  standalone: true,
  selector: 'mp-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule, MatDialogModule, DialogHeaderComponent],
})
export class InfoDialogComponent {
  readonly data: InfoDialogData = inject<InfoDialogData>(MAT_DIALOG_DATA);

  private readonly dialogRef: MatDialogRef<InfoDialogComponent> = inject(MatDialogRef<InfoDialogComponent>);

  closeDialog(): void {
    this.dialogRef.close();
  }
}
