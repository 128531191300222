<div class="headline" *ngIf="headerTitle">
  <h3 class="headline__title">
    <mat-icon *ngIf="headerIcon" class="headline__title-icon material-icons-outlined">{{ headerIcon }}</mat-icon>
    {{ headerTitle }}
  </h3>
</div>

<mp-entity-list-head
  [count]="selectorItems.length"
  [searchTerm]="searchTerm"
  [showSearch]="false"
  (searched)="emitSearched($event)"
>
  <div class="entity-list-head__action-btn" *ngIf="!hideActions">
    <button mat-flat-button (click)="handleAddItemsClicked()">
      <mat-icon>add</mat-icon>
      Hinzufügen
    </button>
  </div>
</mp-entity-list-head>

<mat-card class="selector-item-card">
  <table class="selector-item-table" mat-table [trackBy]="getOrganizationId" [dataSource]="selectorItems">
    <div *matNoDataRow class="no-data-row">{{ emptyText || 'Es gibt keine Zuweisungen.' }}</div>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name </mat-header-cell>

      <mat-cell *matCellDef="let item">{{ item.name }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Aktionen </mat-header-cell>

      <mat-cell *matCellDef="let item">
        <button
          class="action__remove"
          mat-icon-button
          title="Die Zuweisung entfernen"
          (click)="deleteOrToggleItem(item)"
        >
          <mat-icon class="action__remove-icon">{{ item.isRemoved ? 'undo' : 'close' }}</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectorCols" />
    <mat-row *matRowDef="let row; columns: selectorCols" [class.item--removed]="row.isRemoved" />
  </table>
</mat-card>
