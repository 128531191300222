@if (showSearch()) {
  <mp-search-field
    class="search-filter"
    [placeholder]="searchFieldPlaceholder()"
    [value]="searchTerm()"
    (searched)="searchTerm.set($event)"
  >
    <mat-icon mpSearchFieldButton="left">search</mat-icon>
  </mp-search-field>
}

<mp-filter-sort-select
  class="sort-filter"
  [sortOptions]="sortOptions()"
  [sortBy]="sortBy()"
  (sortByChange)="sortByChange.emit($event)"
/>
