import { ScaleLabelConfig } from '../models';

export function getLineChartScaleLabelConfig(element: HTMLElement = document.documentElement): ScaleLabelConfig {
  const computedStyle: CSSStyleDeclaration = getComputedStyle(element);

  const color: string = computedStyle.getPropertyValue('--chart-axis-label-color');
  const fontSize: number = +computedStyle.getPropertyValue('--chart-axis-label-font-size');
  const lineHeight: string = computedStyle.getPropertyValue('--chart-axis-label-line-height');
  const fontFamily: string = computedStyle.getPropertyValue('--chart-axis-label-family');

  return {
    color,
    fontSize,
    lineHeight,
    fontFamily,
  };
}
