<button mat-flat-button (click)="click.emit()">
  <div class="filter-button-content">
    <mat-icon class="material-icons-outlined">filter_alt</mat-icon>

    {{ label() }}

    @if (activeFiltersCount() > 0) {
      <mp-filter-badge [value]="activeFiltersCount()" />
    }
  </div>
</button>
