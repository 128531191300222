import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, HostBinding, Input, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { Observable, throttleTime } from 'rxjs';
import { concatMap, filter, map, mergeMap, pairwise, take } from 'rxjs/operators';

import { UtilPipesModule } from '@core/shared/util';

import { SpinnerComponent } from '../spinner/spinner.component';

import { PagedDataSource } from './paged-data-source';
import { runInZone } from './run-in-zone';

@Component({
  selector: 'mp-infinite-scroll',
  standalone: true,
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  imports: [AsyncPipe, NgTemplateOutlet, ScrollingModule, MatCardModule, SpinnerComponent, UtilPipesModule],
})
export class InfiniteScrollComponent<T, TParams extends Record<string, unknown> | undefined> implements OnInit {
  @HostBinding() readonly class = 'mp-infinite-scroll';

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport!: CdkVirtualScrollViewport;

  @Input() dataSource!: PagedDataSource<T, TParams>;

  /**
   * Height of an individual list-item (in pixels).
   *
   * Initially set to 80px since this is the default template's height.
   * **Needs to be set!**
   */
  @Input() itemHeight = 80;
  @Input() updateInterval = 300;
  @Input() scrollThresholdShare = 0;

  // Optionale Templates, um den Standard überschreiben zu können:
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() loadingFooterTemplate?: TemplateRef<unknown>;
  @Input() loadMoreFooterTemplate?: TemplateRef<unknown>;
  @Input() noMoreFooterTemplate?: TemplateRef<unknown>;

  get scrollThreshold() {
    return this.viewport.getViewportSize() * this.scrollThresholdShare;
  }

  constructor(
    private readonly ngZone: NgZone,
    private readonly destroyRef: DestroyRef,
  ) {}

  scrollTo(pos: number, from: 'top' | 'bottom' = 'top', behavior: 'auto' | 'smooth' = 'smooth'): void {
    this.viewport.scrollTo({ [from]: pos, behavior });
  }

  scrollToTop(behavior: 'auto' | 'smooth' = 'smooth'): void {
    this.scrollTo(0, 'top', behavior);
  }

  scrollToIndex(itemIndex: number, behavior: 'auto' | 'smooth' = 'smooth'): void {
    this.viewport.scrollToIndex(itemIndex, behavior);
  }

  ngOnInit(): void {
    /* Asserting required properties so that developers get
     * an error in case of wrong component usage: */
    assertItemHeight(this.itemHeight);
    assertDataSource(this.dataSource);

    this.viewport
      .elementScrolled()
      .pipe(
        map(() => this.viewport.measureScrollOffset('bottom')),
        pairwise(),
        filter(([lastY, currentY]) => this.wasScrolledIntoThreshold(lastY, currentY)),
        throttleTime(this.updateInterval),
        concatMap(() => this.fetchDataIfNeeded()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.dataSource.queryParams$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({ next: () => this.scrollToTop('auto') });
  }

  private wasScrolledIntoThreshold(lastY: number, currentY: number): boolean {
    const isDownwardScroll = currentY < lastY;
    const isWithinThreshold = currentY <= this.scrollThreshold;
    return isDownwardScroll && isWithinThreshold;
  }

  private fetchDataIfNeeded(): Observable<unknown> {
    return this.dataSource.hasMore$.pipe(
      take(1),
      runInZone(this.ngZone),
      filter((hasMore) => hasMore),
      mergeMap(() => this.dataSource.fetchMore()),
    );
  }
}

function assertItemHeight(itemHeight?: number): void {
  if (itemHeight == null) {
    throw new Error('Property "itemHeight" nicht gesetzt! List-Items brauchen fixe Pixel-Höhe!');
  }
}

function assertDataSource<T, TParams extends Record<string, unknown> | undefined>(
  dataSource?: PagedDataSource<T, TParams>,
): void {
  if (!dataSource) {
    throw new Error('Property "dataSource" nicht gesetzt!');
  }
}
