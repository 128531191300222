import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { first } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AngularPluginRouter, InsightsOrganizationInfoService } from '../services';

@Injectable()
export class CustomAngularPlugin extends AngularPlugin {
  get router(): AngularPluginRouter {
    return this.angularPluginRouter;
  }

  constructor(
    private readonly angularPluginRouter: AngularPluginRouter,
    private readonly insightsOrganizationInfoService: InsightsOrganizationInfoService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
  }

  initializePlugin(): void {
    this.angularPluginRouter.navigationEnd$
      .pipe(
        first(),
        // Track end of the initial navigation.
        // Plugin does not track it by default and due to that the correct initial page view is missing
        tap(() => this.trackPageView({ uri: this.angularPluginRouter.url })),
      )
      .subscribe();
  }

  override trackPageView(pageView: IPageViewTelemetry): void {
    // Add corrected info for the refUri as the default behavior uses only the document.referer which skips non-link navigation
    pageView.refUri = this.angularPluginRouter.previousUrl() || this.document.referrer;

    // Add current organization name to page view related events
    const organizationName: string | null = this.insightsOrganizationInfoService.organizationName();
    if (organizationName) {
      pageView.properties = { ...pageView.properties, organizationName };
    }

    super.trackPageView(pageView);
  }
}
