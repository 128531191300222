<mat-select
  #selectControl
  class="select-control"
  [(ngModel)]="selectedValue"
  [required]="isRequired()"
  [disabled]="isDisabled()"
  [placeholder]="inputPlaceholder() ?? ''"
  [compareWith]="compareWithFunction()"
  [panelWidth]="null"
  hideSingleSelectionIndicator
  (selectionChange)="onSelectionChange()"
  (openedChange)="onOpenedChange($event)"
>
  <mat-select-trigger>
    <ng-container
      *ngTemplateOutlet="
        selectedOptionTemplate() ?? selectedOptionDefaultTemplate.template;
        context: { $implicit: selectedOption(), isActive: true }
      "
    />
  </mat-select-trigger>

  @if (options().length) {
    @for (option of options(); track option.value) {
      <mat-option
        #matOption
        [value]="option.value"
        [disabled]="!!option.disabled"
        [style.font-size]="selectFontSize()"
        [style.min-width]="optionMinWidth()"
      >
        <ng-container
          *ngTemplateOutlet="
            optionTemplate() ?? optionDefaultTemplate.template;
            context: { $implicit: option, isActive: matOption.active }
          "
        />
      </mat-option>
    }
  } @else if (emptyOptionsListTemplate()) {
    <mat-option [style.font-size]="selectFontSize()" [style.min-width]="optionMinWidth()">
      <ng-container *ngTemplateOutlet="emptyOptionsListTemplate()!" />
    </mat-option>
  }
</mat-select>

<ng-template
  #selectedOptionDefaultTemplate="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="optionTemplateContextType"
  let-selectedOption
>
  <mp-selected-option [selectedOption]="selectedOption" />
</ng-template>

<ng-template
  #optionDefaultTemplate="mpTypedTemplate"
  mpTypedTemplate
  [contextType]="optionTemplateContextType"
  let-option
>
  <mp-option [option]="option!" />
</ng-template>
