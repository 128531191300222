import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
// NOTE: This is one of the few places where we allow this import
// eslint-disable-next-line no-restricted-imports
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatPaginatorSelectConfig,
  PageEvent,
} from '@angular/material/paginator';

import { Pagination, PaginationMetadata } from '@core/shared/domain';

import { MatPaginatorIntlGerman } from './mat-paginator-intl-german';

export type PaginationConfig = Pick<PaginationMetadata, 'totalRowCount' | 'currentPage' | 'limit'>;

@Component({
  selector: 'mp-paginator',
  standalone: true,
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatPaginatorModule],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGerman },
    { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: { formFieldAppearance: 'fill' } },
  ],
})
export class PaginatorComponent {
  @HostBinding() readonly class = 'mp-paginator';

  private readonly DEFAULT_PAGE_SIZE_OPTIONS: number[] = [10, 25, 50, 100];

  readonly showFirstLastButtons: InputSignal<boolean> = input<boolean>(true);

  readonly pagination: InputSignal<PaginationConfig | undefined> = input.required<PaginationConfig | undefined>();

  readonly pageSizeOptions: InputSignal<number[]> = input<number[]>(this.DEFAULT_PAGE_SIZE_OPTIONS);

  readonly page: OutputEmitterRef<Pagination> = output<Pagination>();

  protected readonly PAGINATOR_SELECT_CONFIG: MatPaginatorSelectConfig = {
    panelClass: `${this.class}__select-panel`,
  };

  emitPageChange(pageEvent: PageEvent): void {
    const pageChangePagination = this.getPageChangePagination(pageEvent);
    this.page.emit(pageChangePagination);
  }

  getPageChangePagination({ pageIndex, pageSize }: PageEvent): Pagination {
    return { page: pageIndex + 1, pageSize };
  }
}
