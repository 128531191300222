@if (!!searchViews().length) {
  @for (view of searchViews(); track view.searchViewId) {
    <mp-inline-edit
      #editSearchViewInlineEdit
      [inputPlaceholder]="'Name'"
      [value]="view.name"
      (valueSubmit)="updateSearchView(view.searchViewId, $event)"
      (cancel)="trackSearchViewEvent(insightsEventsConfig()?.searchViewUpdateCancel)"
    >
      <div class="search-view">
        <button
          class="search-view__select-view-button"
          [class.search-view__select-view-button--active]="selectedSearchViewId() === view.searchViewId"
          [title]="view.name"
          mat-menu-item
          (click)="selectSearchView(view)"
        >
          <span class="truncate" [class]="{ 'font-bold': selectedSearchViewId() === view.searchViewId }">{{
            view.name
          }}</span>
        </button>

        <button
          class="search-view__action-button"
          mat-icon-button
          (click)="
            trackSearchViewEvent(insightsEventsConfig()?.updateSearchViewButtonClick);
            editSearchViewInlineEdit.showInputField();
            $event.stopPropagation()
          "
        >
          <mat-icon>edit</mat-icon>
        </button>

        <button
          class="search-view__action-button"
          mat-icon-button
          (click)="$event.stopPropagation(); deleteSearchView(view.searchViewId)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mp-inline-edit>
  }
} @else {
  <div class="no-results">Keine Ansichten vorhanden</div>
}

<mat-divider class="divider" [class.divider--active]="createSearchViewInputVisible()" />

<div class="create-search-view">
  <mp-inline-edit
    #createSearchViewInlineEdit
    [inputPlaceholder]="'Name'"
    (valueSubmit)="createSearchView($event)"
    (inputFieldVisibilityChange)="createSearchViewInputVisible.set($event)"
    (cancel)="trackSearchViewEvent(insightsEventsConfig()?.searchViewCreationCancel)"
  >
    <button
      mat-menu-item
      (click)="
        trackSearchViewEvent(insightsEventsConfig()?.newSearchViewButtonClick);
        createSearchViewInlineEdit.showInputField();
        $event.stopPropagation()
      "
    >
      <mat-icon class="create-search-view__button-icon">add</mat-icon>
      <span class="create-search-view__button-label">Ansicht speichern</span>
    </button>
  </mp-inline-edit>
</div>
