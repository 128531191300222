@if (icon) {
  <div class="entity-item__icon">
    <mat-icon class="material-icons-outlined">{{ icon }}</mat-icon>
  </div>
}

<div class="entity-item__label">
  <h5 class="entity-item__label-text truncate">{{ label }}</h5>
  <div class="entity-item__label-meta truncate">
    @if (chipsLabels?.length) {
      <mat-chip-set>
        @for (chipLabel of chipsLabels; track $index) {
          <mat-chip> {{ chipLabel }}</mat-chip>
        }
      </mat-chip-set>
    } @else {
      {{ meta }}
    }
  </div>
</div>

<div class="entity-item__actions">
  <ng-content select="button" />
  <ng-content select="additional-icon" />

  @if (editMode) {
    @if (!removed) {
      <button mat-icon-button (click)="removeClicked()">
        <mat-icon>clear</mat-icon>
      </button>
    } @else {
      <button mat-icon-button (click)="undoClicked()">
        <mat-icon>undo</mat-icon>
      </button>
    }
  }
</div>
