import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { filterUndefined } from '@core/shared/util';

import { ConfirmDialogData } from '../../confirm-dialog/confirm-dialog-data';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Injectable()
export class UnsavedChangesHandlerService {
  constructor(private dialog: MatDialog) {}

  showUnsavedChangesConfirmationDialog(infoMessage: string): Observable<boolean> {
    return this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        data: {
          title: 'Ungespeicherte Änderungen',
          message: infoMessage,
          trueButtonText: 'Änderungen verwerfen und fortfahren',
          falseButtonText: 'Abbrechen',
        },
      })
      .afterClosed()
      .pipe(filterUndefined());
  }
}
