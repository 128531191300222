import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';

import { TypedTemplateDirective } from '@core/shared/util';

import { SelectOption } from '../option';
import { SelectComponent } from '../select';

@Component({
  selector: 'mp-filter-sort-select',
  standalone: true,
  templateUrl: './filter-sort-select.component.html',
  styleUrl: './filter-sort-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectComponent, TypedTemplateDirective],
})
export class FilterSortSelectComponent {
  readonly sortBy: InputSignal<string | undefined> = input<string | undefined>();
  readonly sortOptions: InputSignal<SelectOption<string>[]> = input.required<SelectOption<string>[]>();

  readonly sortByChange: OutputEmitterRef<string> = output<string>();

  handleSortByChange(sortBy: string | undefined): void {
    if (sortBy !== undefined) {
      this.sortByChange.emit(sortBy);
    }
  }
}
