<small class="label">{{ label }}</small>

<mat-chip-set>
  @for (selectedOption of selectedOptions; track $index) {
    <mat-chip removable="true" (removed)="removeSelectedItem(selectedOption.value)">
      {{ selectedOption.label }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  }

  @if (availableOptions.length) {
    <button mat-icon-button type="button" color="accent" [matMenuTriggerFor]="menu">
      <mat-icon>add</mat-icon>
    </button>
  }

  <mat-menu #menu="matMenu" (closed)="onTouched()">
    @for (option of availableOptions; track $index) {
      <button mat-menu-item (click)="selectItem(option.value)">
        {{ option.label }}
      </button>
    }
  </mat-menu>
</mat-chip-set>

