<mat-form-field
  class="dropdown-form-field"
  [ngClass]="{
    'dropdown-form-field--standard-appearance': appearance() === 'standard',
    'dropdown-form-field--inline': inline()
  }"
  appearance="fill"
>
  @if (dropdownIcon(); as dropdownIcon) {
    <mat-icon matPrefix class="dropdown-form-field__icon material-icons-outlined" [mpIcon]="dropdownIcon" />
  }

  @if (!inline()) {
    <mat-label class="dropdown-form-field__label">
      @if (labelTemplate(); as placeholderTemplate) {
        <ng-container *ngTemplateOutlet="placeholderTemplate" />
      } @else {
        {{ label() }}
      }
    </mat-label>
  }

  <mp-select
    [disabled]="isDisabled()"
    [(selectedValue)]="selectedValue"
    [options]="options()"
    [required]="required()"
    [placeholder]="placeholder()"
    [customCompareWithFunction]="customCompareWithFunction()"
    [selectedOptionTemplate]="selectedOptionTemplate()"
    [optionTemplate]="optionTemplate()"
    [emptyOptionsListTemplate]="emptyOptionsListTemplate()"
    (selectedValueChange)="onSelectionChange()"
    (openChange)="onTouched()"
  />

  @if (errorMessage()) {
    <mat-error>{{ errorMessage() }}</mat-error>
  }
</mat-form-field>
