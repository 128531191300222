import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { UploadFormStatus } from '../upload-form/upload-form.component';

@Component({
  selector: 'mp-file-upload-label',
  standalone: true,
  templateUrl: './file-upload-label.component.html',
  styleUrl: './file-upload-label.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UpperCasePipe, MatButtonModule, MatIconModule],
})
export class FileUploadLabelComponent {
  readonly componentCssClass = 'mp-file-upload-label';

  @HostBinding('class')
  get class(): string {
    return `${this.componentCssClass} ${this.getComponentStateCssClass()}`;
  }

  readonly buttonLabel: InputSignal<string> = input.required<string>();
  readonly iconName: InputSignal<string> = input.required<string>();
  readonly acceptedFileTypes: InputSignal<string> = input<string>('*');
  readonly status: InputSignal<UploadFormStatus> = input<UploadFormStatus>(UploadFormStatus.PreUpload);
  readonly errorMessage: InputSignal<string | undefined> = input<string>();

  protected readonly UploadFormStatus = UploadFormStatus;

  private getComponentStateCssClass(): string {
    let statusCssModifier = '';
    switch (this.status()) {
      case UploadFormStatus.Success: {
        statusCssModifier = '--success';
        break;
      }
      case UploadFormStatus.Error: {
        statusCssModifier = '--error';
        break;
      }
      default:
        break;
    }

    return statusCssModifier ? this.componentCssClass + statusCssModifier : '';
  }
}
