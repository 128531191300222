<button mat-raised-button class="action-button" (click)="revertToSearchView()">
  <mat-icon class="action-button__icon">undo</mat-icon>
  Zurück zur Ansicht
</button>

<button
  mat-raised-button
  class="action-button"
  [matMenuTriggerFor]="searchViewListMenu"
  #trigger="matMenuTrigger"
  (click)="createSearchViewInlineEdit.showInputField()"
>
  <mat-icon class="action-button__icon">add</mat-icon>
  Als neue Ansicht speichern
</button>

<mat-menu #searchViewListMenu class="{{ class }}__menu no-padding">
  <mp-inline-edit
    class="{{ class }}__search-view-name-input"
    #createSearchViewInlineEdit
    [inputPlaceholder]="'Name'"
    (valueSubmit)="createSearchView($event)"
    (inputFieldVisibilityChange)="!$event && trigger.closeMenu()"
  />
</mat-menu>

<button mat-raised-button class="action-button" (click)="updateSearchView()">
  <mat-icon class="material-icons-outlined action-button__icon">save</mat-icon>
  Ansicht überschreiben
</button>
