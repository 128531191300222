import { NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';

import { InfoDialogComponent } from './info-dialog.component';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  panelClass: 'mp-info-dialog-container',
};

@NgModule({
  imports: [InfoDialogComponent],
  exports: [InfoDialogComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DEFAULT_DIALOG_CONFIG }],
})
export class InfoDialogModule {}
