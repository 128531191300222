import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { FilterBadgeComponent } from '../../filter-badge';
import { FilterItemSelectorComponent } from '../../filter-item-selector/component/filter-item-selector.component';
import { FilterSection } from '../../filter-item-selector/filter-item';

import { FilterInputOptionsData } from './filter-input-options-data';

type FilterSectionStats = Record<number | string, Record<number | string, number>>;
type FilterSectionIcons = Record<number | string, Record<number | string, string>>;

interface CollapsedSectionsDataInfo {
  value: string | number | null;
  icon?: string;
  isRadio?: boolean;
}

@Component({
  selector: 'mp-filter-input-options',
  standalone: true,
  templateUrl: './filter-input-options.component.html',
  styleUrl: './filter-input-options.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,

    FilterItemSelectorComponent,
    FilterBadgeComponent,
  ],
})
export class FilterInputOptionsComponent<T> {
  @HostBinding('class') readonly classes = 'mp-filter-input mp-filter-input-options';

  private _filterSections: Array<FilterSection<T, FilterInputOptionsData>> = [];

  @Input() set filterSections(filterSections: Array<FilterSection<T, FilterInputOptionsData>>) {
    this._filterSections = [...filterSections];
    this.radioFiltersMap = this.buildRadioFiltersMap();
    this.multiSelectFiltersMap = this.buildMultiSelectFiltersMap();
    this._filterSections.forEach((item) => this.setCollapseSectionsSelectedDataInfo(item));
  }

  get filterSections() {
    return this._filterSections;
  }

  @Input()
  filterStats: FilterSectionStats = {};
  @Input() filterIcons: FilterSectionIcons = {};
  @Input() displayStatistics = false;

  @Output() readonly valueChanges = new EventEmitter<Record<string, Array<T>>>();

  collapseSectionsState: Record<string, boolean> = {};
  showSearchSectionsState: Record<string, boolean> = {};
  radioFiltersMap: Record<string, FilterSection> = {};
  multiSelectFiltersMap: Record<string, FilterSection> = {};
  collapseSectionsSelectedDataInfo: Record<string, CollapsedSectionsDataInfo> = {};
  hasBeenExpanded: Record<string, boolean> = {};

  emitValueChanges(value: Array<T>, filterSection: FilterSection<T>): void {
    // TODO: Das ist nicht gut, dass hier nur ein Key ankommt.
    this.valueChanges.emit({ [filterSection.key]: value });
    this.setCollapseSectionsSelectedDataInfo(filterSection);
  }

  private buildRadioFiltersMap(): Record<string, FilterSection> {
    return this.filterSections.reduce((prev, curr) => {
      return {
        ...prev,
        ...(!curr.multiselect ? { [curr.key]: curr } : {}),
      };
    }, {});
  }

  private buildMultiSelectFiltersMap(): Record<string, FilterSection> {
    return this.filterSections.reduce((prev, curr) => {
      return {
        ...prev,
        ...(curr.multiselect ? { [curr.key]: curr } : {}),
      };
    }, {});
  }

  private setCollapseSectionsSelectedDataInfo(filterSection: FilterSection): void {
    this.collapseSectionsSelectedDataInfo = {
      ...this.collapseSectionsSelectedDataInfo,
      [filterSection.key]: this.getFilterStats(filterSection),
    };
  }

  private getFilterStats(filterSection: FilterSection): CollapsedSectionsDataInfo {
    if (this.radioFiltersMap[filterSection.key]) {
      const option = filterSection.options.find((option) => option.selected);
      return {
        value: option?.label ?? '',
        icon: option?.icon,
        isRadio: true,
      };
    }

    const selectedOptionsQuantity = filterSection.options.filter((option) => !!option.selected).length;
    return {
      value: selectedOptionsQuantity > 0 ? selectedOptionsQuantity : null,
    };
  }

  isFilterSectionExpanded(section: FilterSection<T, FilterInputOptionsData>): boolean {
    return this.collapseSectionsState[section.key] ?? section.data?.expandedInitially ?? false;
  }

  setFilterPanelExpansionState(
    filterSection: FilterSection<T, FilterInputOptionsData>,
    isPanelExpanded: boolean,
  ): void {
    if (this.isFilterSectionExpanded(filterSection) !== isPanelExpanded) {
      filterSection.data?.onExpandedChange?.(isPanelExpanded);
    }

    this.collapseSectionsState = {
      ...this.collapseSectionsState,
      [filterSection.key + '']: isPanelExpanded,
    };
  }

  toggleSearchInput(key: string | number): void {
    this.showSearchSectionsState = {
      ...this.showSearchSectionsState,
      [`${key}`]: !this.showSearchSectionsState[`${key}`],
    };
  }

  onFilterExpanded(expanded: boolean, key: string | number): void {
    this.hasBeenExpanded = {
      ...this.hasBeenExpanded,
      [`${key}`]: expanded,
    };
  }
}
