<div class="active-filter-header" [class]="{ 'active-filter-header--collapsed': collapsed() }">
  <div class="active-filter-header__main">
    <button mat-icon-button class="active-filter-header__button" (click)="collapseFilter()">
      <mat-icon>{{ collapsed() ? 'arrow_right' : 'arrow_drop_down' }}</mat-icon>
    </button>
    <span class="active-filter-header__label">
      Verwendete Filter
      <span class="active-filter-header__count">({{ selectedFiltersCount() }})</span>
    </span>
  </div>

  <div class="active-filter-header__actions">
    <ng-content />
  </div>
</div>

@if (!collapsed()) {
  <div class="active-filter-content">
    @for (filter of activeFilters(); track $index) {
      <mp-custom-chips
        [useSmallChips]="true"
        [groupName]="filter.groupName"
        [chips]="filter.properties"
        (remove)="removeFilter($event)"
      />
    }
  </div>
}
