<span class="label">{{ label }}</span>
<mat-chip-set>
  @for (item of selectedItems; track $index) {
    <mat-chip removable="true" (removed)="removeItem(item)">
      {{ getDisplayValueForItem(item) }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  }
  <button mat-icon-button color="accent" [matMenuTriggerFor]="menu">
    <mat-icon>add</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    @for (item of availableItems; track $index) {
      <button mat-menu-item (click)="addItem(item)">
        {{ getDisplayValueForItem(item) }}
      </button>
    }
  </mat-menu>
</mat-chip-set>
