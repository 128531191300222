import { CartesianScaleTypeRegistry, ScaleOptionsByType } from 'chart.js';

import { ScaleLabelConfig } from '../models';

export function getScaleDefaultConfig(
  scaleText: string,
  { color, fontFamily, fontSize, lineHeight }: ScaleLabelConfig,
): ScaleOptionsByType<keyof CartesianScaleTypeRegistry> {
  return {
    title: {
      display: true,
      text: scaleText,
      font: {
        size: fontSize,
        family: fontFamily,
        lineHeight,
      },
      color,
    },
    beginAtZero: true,
    suggestedMax: 100,
  } as ScaleOptionsByType<keyof CartesianScaleTypeRegistry>;
}
