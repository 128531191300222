import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  input,
  model,
  output,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { IconDirective, TypedTemplateDirective } from '@core/shared/util';
import { LogicalChoiceFilter } from '@mp/shared/data-access';

import { SelectOption } from '../option';
import { SelectComponent } from '../select';

@Component({
  selector: 'mp-logical-choice-dropdown',
  standalone: true,
  templateUrl: './logical-choice-dropdown.component.html',
  styleUrls: ['./logical-choice-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, MatIconModule, IconDirective, SelectComponent, TypedTemplateDirective],
})
export class LogicalChoiceDropdownComponent {
  readonly icon: InputSignal<string | undefined> = input<string | undefined>();

  readonly title: InputSignal<string | undefined> = input<string | undefined>();

  readonly selectedValue: ModelSignal<LogicalChoiceFilter> = model<LogicalChoiceFilter>(LogicalChoiceFilter.All);

  readonly selectionChange: OutputEmitterRef<LogicalChoiceFilter> = output<LogicalChoiceFilter>();

  readonly logicalChoiceOptions: SelectOption<LogicalChoiceFilter>[] = [
    { label: 'Alle', value: LogicalChoiceFilter.All },
    { label: 'Ja', value: LogicalChoiceFilter.Yes },
    { label: 'Nein', value: LogicalChoiceFilter.No },
  ];

  onSelectionChange(selectedValue: LogicalChoiceFilter | undefined): void {
    if (!selectedValue) {
      return;
    }

    this.selectionChange.emit(selectedValue);
  }
}
