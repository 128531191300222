import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  InputSignal,
  ModelSignal,
  OutputEmitterRef,
  TemplateRef,
  forwardRef,
  input,
  model,
  output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

import { IconDirective, TemplateContext, TypedTemplateDirective } from '@core/shared/util';

import { OptionComponent } from '../../option';
import { SelectComponent } from '../../select';
import { AutocompletePanelComponent } from '../autocomplete-panel/autocomplete-panel.component';

export type AutocompleteInputAppearance = 'standard' | 'fill';

export type AutocompleteInputMode = 'input' | 'chip-item';

export type ChipItemTemplateContext<ImplicitType> = TemplateContext<ImplicitType>;

export type ChipItemTemplateRef<ImplicitType> = TemplateRef<TemplateContext<ImplicitType>>;

@Component({
  selector: 'mp-autocomplete-input',
  standalone: true,
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    NgClass,
    FormsModule,

    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatAutocompleteModule,
    MatLegacyButtonModule,
    MatChipsModule,

    IconDirective,
    SelectComponent,
    OptionComponent,
    TypedTemplateDirective,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutocompleteInputComponent<T> implements ControlValueAccessor {
  @HostBinding() readonly class = 'mp-autocomplete-input';

  readonly selectedValue: ModelSignal<T | undefined> = model<T>();

  readonly autocompletePanel: InputSignal<AutocompletePanelComponent<T>> =
    input.required<AutocompletePanelComponent<T>>();

  readonly autocompleteInputMode: InputSignal<AutocompleteInputMode> = input<AutocompleteInputMode>('input');
  readonly appearance: InputSignal<AutocompleteInputAppearance> = input<AutocompleteInputAppearance>('standard');
  readonly inline: InputSignal<boolean> = input<boolean>(false);

  readonly disabled: ModelSignal<boolean> = model<boolean>(false);
  readonly required: InputSignal<boolean> = input<boolean>(false);
  readonly clearable: InputSignal<boolean> = input<boolean>(false);

  readonly autocompleteIcon: InputSignal<string | undefined> = input<string>();

  readonly label: InputSignal<string | undefined> = input<string>();
  readonly labelTemplate: InputSignal<TemplateRef<unknown> | undefined> = input<TemplateRef<unknown>>();
  readonly placeholder: InputSignal<string | undefined> = input<string>();

  readonly errorMessage: InputSignal<string | undefined> = input<string>();

  readonly chipItemTemplate: InputSignal<ChipItemTemplateRef<T> | undefined> = input<ChipItemTemplateRef<T>>();

  readonly searchTermChange: OutputEmitterRef<string> = output<string>();

  readonly clear: OutputEmitterRef<void> = output<void>();

  readonly chipItemTemplateContextType!: ChipItemTemplateContext<T>;

  protected onChange = (_value: T | undefined) => {};

  protected onTouched = () => {};

  constructor(private readonly cdr: ChangeDetectorRef) {}

  onSearchTermChange(searchTerm: string): void {
    if (!searchTerm && this.selectedValue()) {
      this.selectedValue.set(undefined);
    }

    this.searchTermChange.emit(searchTerm);
  }

  onClear(): void {
    this.selectedValue.set(undefined);
    this.searchTermChange.emit('');

    this.clear.emit();
  }

  onSelectedValueChange(value: T | undefined): void {
    this.searchTermChange.emit('');
    this.onChange(value);
  }

  resetChipInput(input: HTMLInputElement): void {
    input.value = '';
  }

  writeValue(value: T | undefined): void {
    this.selectedValue.set(value);
    // NOTE: Mark component for check is necessary as value accessor will not trigger that by default with reactive forms
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: T | undefined) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
}
