import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

@Component({
  selector: 'mp-table-items-info',
  standalone: true,
  templateUrl: './table-items-info.component.html',
  styleUrl: './table-items-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableItemsInfoComponent {
  readonly infoTitle: InputSignal<string> = input.required<string>();

  readonly totalItems: InputSignal<number | undefined> = input<number>();

  readonly small: InputSignal<boolean> = input<boolean>(false);
}
