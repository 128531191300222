import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'mp-filters-context-area',
  standalone: true,
  templateUrl: './filters-context-area.component.html',
  styleUrl: './filters-context-area.component.scss',
  host: {
    class: 'mp-filters-context-area',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule],
})
export class FiltersContextAreaComponent {}
