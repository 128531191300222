import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  Optional,
  OutputEmitterRef,
  WritableSignal,
  input,
  output,
  signal,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatMenuModule } from '@angular/material/menu';

import { InsightsEvent } from '@mp/shared/app-insights/domain';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

import { InlineEditComponent } from '../../../inline-edit/inline-edit.component';
import { SearchView, SearchViewMenuInsightsEventsConfig, SearchViewNameUpdate } from '../../models';

@Component({
  selector: 'mp-search-view-menu',
  standalone: true,
  templateUrl: './search-view-menu.component.html',
  styleUrl: './search-view-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule, MatButtonModule, MatIconModule, MatDividerModule, InlineEditComponent],
})
export class SearchViewMenuComponent<T extends object> {
  readonly searchViews: InputSignal<SearchView<T>[]> = input.required<SearchView<T>[]>();

  readonly selectedSearchViewId: InputSignal<string | undefined> = input.required<string | undefined>();

  readonly insightsEventsConfig: InputSignal<SearchViewMenuInsightsEventsConfig | undefined> =
    input<SearchViewMenuInsightsEventsConfig>();

  readonly searchViewSelect: OutputEmitterRef<SearchView<T>> = output<SearchView<T>>();

  readonly searchViewCreate: OutputEmitterRef<string> = output<string>();

  readonly searchViewUpdate: OutputEmitterRef<SearchViewNameUpdate> = output<SearchViewNameUpdate>();

  readonly searchViewDelete: OutputEmitterRef<string> = output<string>();

  protected readonly createSearchViewInputVisible: WritableSignal<boolean> = signal<boolean>(false);

  constructor(@Optional() private readonly insightsEventsTrackingService: InsightsEventsTrackingService | null) {}

  selectSearchView(searchView: SearchView<T>): void {
    this.trackSearchViewEvent(this.insightsEventsConfig()?.selectSearchView);

    this.searchViewSelect.emit(searchView);
  }

  createSearchView(searchViewName: string): void {
    this.trackSearchViewEvent(this.insightsEventsConfig()?.searchViewCreationConfirm);

    this.searchViewCreate.emit(searchViewName);
  }

  updateSearchView(searchViewId: string, name: string): void {
    this.trackSearchViewEvent(this.insightsEventsConfig()?.searchViewUpdateConfirm);

    this.searchViewUpdate.emit({ searchViewId, name });
  }

  deleteSearchView(searchViewId: string): void {
    this.searchViewDelete.emit(searchViewId);
  }

  trackSearchViewEvent(event?: InsightsEvent): void {
    if (!event) {
      return;
    }

    this.insightsEventsTrackingService?.trackEvent(event);
  }
}
