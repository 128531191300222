<mp-select
  #select
  placeholder="Sortierung"
  [options]="sortOptions()"
  [selectedValue]="sortBy()"
  (selectedValueChange)="handleSortByChange($event)"
  [selectedOptionTemplate]="selectedOptionTemplate.template"
>
  <ng-template
    #selectedOptionTemplate="mpTypedTemplate"
    mpTypedTemplate
    [contextType]="select.optionTemplateContextType"
    let-option
  >
    @if (option; as selectedOption) {
      <span class="selected-option-label">
        Sortierung: <span class="font-bold">{{ selectedOption.label }}</span>
      </span>
    }
  </ng-template>
</mp-select>
