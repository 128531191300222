import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';

import { UnsavedChangesHandlerService } from './services';

@NgModule({
  imports: [MatDialogModule, ConfirmDialogModule],
  providers: [UnsavedChangesHandlerService],
})
export class ConfirmUnsavedChangesModule {}
