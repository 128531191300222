<mat-paginator
  class="paginator"
  [showFirstLastButtons]="showFirstLastButtons()"
  [selectConfig]="PAGINATOR_SELECT_CONFIG"
  [length]="pagination()?.totalRowCount ?? 0"
  [pageIndex]="(pagination()?.currentPage ?? 1) - 1"
  [pageSize]="pagination()?.limit ?? 1"
  [pageSizeOptions]="pageSizeOptions()"
  (page)="emitPageChange($event)"
/>
