import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  NgZone,
  Signal,
  WritableSignal,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { ArcElement, Chart, ChartOptions, DoughnutController, Plugin } from 'chart.js';

import { ChartArea } from '../../models';
import { getDoughnutInnerAreaPlugin } from '../../plugins';
import { AbstractChart } from '../abstract-chart.directive';
import { ChartBaseComponent } from '../chart-base';

Chart.register(DoughnutController, ArcElement);

@Component({
  selector: 'mp-doughnut-chart',
  standalone: true,
  templateUrl: './doughnut-chart.component.html',
  styleUrl: './doughnut-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ChartBaseComponent],
})
export class DoughnutChartComponent extends AbstractChart<'doughnut'> {
  readonly options: InputSignal<ChartOptions<'doughnut'>> = input<ChartOptions<'doughnut'>>(
    {} as ChartOptions<'doughnut'>,
  );

  protected readonly legendValues: Signal<number[]> = computed(() => {
    return this.data().datasets[0].data as number[];
  });

  protected readonly chartArea: WritableSignal<ChartArea | undefined> = signal<ChartArea | undefined>(undefined);

  private readonly chartAreaPlugin: Plugin<'doughnut'> = getDoughnutInnerAreaPlugin((chartArea) =>
    this.ngZone.run(() => this.chartArea.set(chartArea)),
  );

  readonly doughnutChartPlugins: Plugin<'doughnut'>[] = [this.chartAreaPlugin];

  private readonly ngZone: NgZone = inject(NgZone);
}
