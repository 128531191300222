import { Signal } from '@angular/core';

/**
 * Abstract signature of the service for providing user organization related info for tracking events.
 * Example usage: providers: [{provide: InsightsOrganizationInfoService, useExisting: AppInsightsOrganizationInfoService}]
 */
export abstract class InsightsOrganizationInfoService {
  /**
   * Currently selected organization name
   */
  abstract readonly organizationName: Signal<string | null>;
}
