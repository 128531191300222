import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignalWithTransform,
  OutputEmitterRef,
  input,
  output,
} from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'mp-dialog-header',
  standalone: true,
  templateUrl: './dialog-header.component.html',
  styleUrl: './dialog-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyButtonModule, MatIconModule, MatDialogModule],
})
export class DialogHeaderComponent {
  readonly narrowPadding: InputSignalWithTransform<boolean, BooleanInput> = input(false, {
    transform: coerceBooleanProperty,
  });

  readonly closeDialog: OutputEmitterRef<void> = output<void>();
}
