<mat-slide-toggle
  class="global-article-toggle"
  [checked]="checked()"
  [disabled]="disabled()"
  hideIcon
  (change)="onToggleChange($event.checked)"
>
  <span class="global-article-toggle__label">
    <span class="global-article-toggle__text">Global Artikel</span>
    <mat-icon svgIcon="global_article_toggle" />
  </span>
</mat-slide-toggle>
