import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  InputSignal,
  QueryList,
  Signal,
  computed,
  input,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatMenuItem, MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'mp-overview-item',
  standalone: true,
  templateUrl: './overview-item.component.html',
  styleUrl: './overview-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, RouterLinkActive, MatButtonModule, MatMenuModule, MatIconModule, MatRippleModule],
})
export class OverviewItemComponent {
  @HostBinding() readonly class = 'mp-overview-item';

  @ContentChildren(MatMenuItem) contentChildren!: QueryList<MatMenuItem>;

  readonly path: InputSignal<string | undefined> = input.required<string | undefined>();

  readonly isHighlighted: InputSignal<boolean> = input<boolean>(false);

  protected readonly contextMenuButtonVisible: Signal<boolean> = computed(() => this.contentChildren.length > 0);
}
