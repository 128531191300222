<mp-dialog-header narrowPadding (closeDialog)="closeDialog()">
  <span class="dialog-header-label">
    @if (data.hasIcon) {
      <mat-icon class="dialog-header-label__icon">check</mat-icon>
    }
    {{ data.title }}
  </span>
</mp-dialog-header>

<div mat-dialog-content class="dialog-content">
  {{ data.message }}
</div>

<div mat-dialog-actions class="dialog-actions" [align]="'end'">
  <button mat-flat-button color="accent" mat-dialog-close>Schließen</button>
</div>
