import { Injectable } from '@angular/core';
// NOTE: This is one of the few places where we allow this import
// eslint-disable-next-line no-restricted-imports
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlGerman extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Elemente pro Seite';
  // We set these as empty strings to ensure that there won't be a tooltip
  override nextPageLabel = '';
  override previousPageLabel = '';
  override firstPageLabel = '';
  override lastPageLabel = '';

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 von ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };
}
