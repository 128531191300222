<div class="context-area-header">
  @if (staticViews().length > 0) {
    <mp-context-tabs
      class="context-area-header__tabs"
      [contextTabs]="staticViews()"
      [(selectedTab)]="selectedStaticView"
    />
  }

  <div class="context-area-header__search-views">
    <ng-content />
  </div>
</div>

<div class="filter-views-actions">
  <ng-content select="[filter-views-actions]" />
</div>
