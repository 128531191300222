<nav class="navigation-tabs-menu" mat-tab-nav-bar mat-stretch-tabs="false" [tabPanel]="tabPanel">
  @for (tabLink of tabLinks(); track tabLink) {
    <a mat-tab-link [routerLink]="tabLink.link" routerLinkActive #rla="routerLinkActive" [active]="rla.isActive"
      >{{ tabLink.label }}
    </a>
  }
</nav>

<mat-tab-nav-panel #tabPanel>
  <div class="navigation-tab-content">
    <ng-content />
  </div>
</mat-tab-nav-panel>
